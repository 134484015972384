.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;  // for enlarging the click area
  margin: -8px;  // and keeping the element intact
  .mp-arrow-right {
    color: var(--ion-color-tertiary);
  }
  i[class^='mp-'] {
    margin-right: 0;
    font-size: 22px;
  }
  &:hover {
    background-color: var(--color-bg-selectitem-hover);
  }
  .first {
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
    }
  }
}