.header-wrapper {
    height: 120px;
    padding: 24px;
    background-color: var(--ion-color-white);
    display: grid;
    place-items: center;

    .org-logo, .org-logo::part(image) {
      max-height: 72px;
    }
  }