// generate utitlity classes with rem value
$spaceamounts: (
  '0': 0,
  '4': 0.25,
  '6': 0.37,
  '8': 0.5,
  '12': 0.75,
  '16': 1,
  '18': 1.125,
  '20': 1.25,
  '24': 1.5,
  '28': 1.75,
  '32': 2,
  '40': 2.5,
  '48': 3,
  '60': 3.75,
  '75': 4.68,
  '100': 6.25,
);
$sides: (
  '': 'all',
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
);

$fontsize: (
  '0'
  '4'
  '6'
  '8'
  '12'
  '14'
  '16'
  '18'
  '20'
  '24'
  '28'
);
  
@each $font in $fontsize {
  .f#{$font} {
    font-size: #{$font}px;
  }
}

@each $space, $name in $spaceamounts {
  @each $prefix, $value in $sides {
    $property: if($prefix == '', '', -#{$value});
    .m#{$prefix}#{$space} {
      margin#{$property}: #{$name}rem;
    }
    .p#{$prefix}#{$space} {
      padding#{$property}: #{$name}rem;
    }
  }
}
