.box-container {
  background-color: var(--ion-color-box-bg);
  border: 1px solid var(--ion-color-box-border);
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;

  &.green-bg {
    background-color: var(--ion-color-success);
  }
  
  &.grey-bg {
    background-color: var(--ion-color-box-title-bg);
  }

  &.yellow-bg {
    background-color: var(--ion-color-gold);
  }
  
  &.blue-bg {
    background-color: #E4F3FF;
  
  }

  .box-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    padding: 12px;
    text-align: left;
  }
  .box-content {
    color: var(--color-text-primary);
    font-size: 14px;
    background-color: var(--ion-color-box-bg);
    border: 1px solid var(--ion-color-box-border);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
  }
}

.subtitle {
  justify-content: space-between;
  align-items: center;
  color: var(--ion-color-warning);
  font-weight: 400;
}
.btn {
  margin: 8px 0  0;
}