

.ion-margin-start {
	color: var(--ion-color-dark);
}

.ion-padding-horizontal {
	padding: 0 !important
}
.pickFlag {
	z-index: 99;
}
.z-0 {
	z-index: 0;
}
.visible {
	overflow: visible !important;
}

.link-click-event {
	display: block;
}

.button.small {
	margin-top: 32px;
	width: 100%;
}