ion-col {
	text-align: left;
}

.notification {
	order: -100;
}

.notification-success {
	background-color: var(--ion-color-success);
	color: var(--ion-color-warning);
	padding: 16px 0;
}

.notification-warning {
	background-color: var(--ion-color-warning);
	color: var(--ion-color-info-text);
	padding: 16px 0;
}

.notification-info {
	background-color: var(--ion-color-info-bg);
	color: var(--ion-color-info-text);
	padding: 16px 0;
}

.notification-error, .notification-danger {
	background-color: var(--ion-color-error-bg);
	color: var(--ion-color-error-text);
	padding: 16px 0;

	p {
		color: var(--ion-color-error-text);

		a {
			color: var(--ion-color-error-text);
			text-decoration: underline;			
		}
	}
}

.closable {
	i {
		margin-right: 5%;
		margin-left: 5%;
	}
}

.unclosable {
	display: none;
}
