.button {
    background: transparent !important;
    border-radius: 8px !important;
    

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
    
        .icon {
            color: var(--ion-color-tertiary);
            margin: 0 0 0 8px;
            font-size: 22px;
        }
    }
}
