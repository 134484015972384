@import "./mixin";

@font-face {
  font-family: "meetplans";
  src: url("../fonts/meetplans.eot?1wbou2");
  src: url("../fonts/meetplans.eot?1wbou2#iefix") format("embedded-opentype"),
    url("../fonts/meetplans.ttf?1wbou2") format("truetype"),
    url("../fonts/meetplans.woff?1wbou2") format("woff"),
    url("../fonts/meetplans.svg?1wbou2#meetplans") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "meetplans" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mp-kebab:before {
  content: "\e919";
}
.mp-meatballs:before {
  content: "\e91a";
}
.mp-bell:before {
  content: "\e900";
}
.mp-send:before {
  content: "\e901";
}
.mp-search:before {
  content: "\e902";
}
.mp-capture:before {
  content: "\e903";
}
.mp-download:before {
  content: "\e904";
}
.mp-plus:before {
  content: "\e905";
}
.mp-arrow-down-left:before {
  content: "\e906";
}
.mp-chevron-up:before {
  content: "\e907";
}
.mp-chevron-down:before {
  content: "\e908";
}
.mp-external-link:before {
  content: "\e909";
}
.mp-arrow-right:before {
  content: "\e90a";
}
.mp-arrow-left:before {
  content: "\e90b";
}
.mp-close:before {
  content: "\e90c";
}
.mp-phone:before {
  content: "\e90d";
}
.mp-date:before {
  content: "\e90e";
}
.mp-calendar-move:before {
  content: "\e90f";
}
.mp-calendar-add:before {
  content: "\e910";
}
.mp-calendar:before {
  content: "\e911";
}
.mp-chat:before {
  content: "\e912";
}
.mp-video:before {
  content: "\e913";
}
.mp-paper:before {
  content: "\e914";
}
.mp-check:before {
  content: "\e915";
}
.mp-camera:before {
  content: "\e916";
}
.mp-paperclip:before {
  content: "\e917";
}
.mp-person:before {
  content: "\e918";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --box-shadow: none !important;
  font-family: var(--font-family);
}

html {
  font-family: var(--font-family);
}

body {
  font-family: var(--font-family-body);
  font-size: var(--font-size-body-base);
}
a {
  background-color: transparent;
  color: var(--color-text-link);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  line-height: 1.2;
  font-family: var(--font-family-headline);
}
h1 {
  margin-top: 20px;
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
  line-height: var(--line-height-h1);
}
h2 {
  margin-top: 18px;
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
  line-height: var(--line-height-h2);
}
h3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-h3);
  line-height: var(--line-height-h3);
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

small {
  font-size: 75%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/*# sourceMappingURL=typography.css.map */


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.danger {
  color: var(--color-text-danger);

  a {
    color: var(--color-text-danger);
    text-decoration: underline;
  }
}

.danger-border {
  border: 1px solid --color-text-danger;
}

.header {
  height: 120px;
  background-color: var(--ion-color-white);
  display: grid;
  place-items: center;
  .caritas-logo {
    width: 100px;
  }
}

.f14 {
  font-size: 14px;
}

.link-click-event {
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-text-link);
}

.select-input {
	background-color: var(---color-bg-selectmenu);
	color: var(--color-text-selectitem);
	border-radius: 4px;
	border: 1px solid var( --color-border-selectmenu);
	padding: 0.5rem 1rem;
	&:hover,
	&:focus {
		.select-icon {
			color: var(-color-select-arrow-hover);
		}
	}
}

ion-content, ion-footer {
  --ion-background-color: var(--color-bg-main);
}

p {
  color: var(--color-text-primary);
  line-height: 21px;
  @media (max-width: 375px) {
    font-size: 14px;
  }
}

.header-md::after {
  background-image: none !important;
}

.item .item-content {
  background-color: transparent !important;
}

.button {
  font-size: var(--font-size-button-regular);
  height: 40px;
  font-weight: var(--font-weight-button);
  line-height: var(--line-height-button-regular);

  color: var(--color-text-button-basic);
  border-radius: var(--button-border-radius);

  box-sizing: border-box;

  &.small {
    font-size: var(--font-size-button-small);
    line-height: var(--line-height-button-small);
  }

  &.primary {
    border: 1px solid var(--color-border-button-primary);
    background-color: var(--color-bg-button-primary-default);
    color: var(--color-text-primary);
    
    &:active {
      background-color: var(--color-bg-button-primary-active);
    }

    &:hover {
      background-color: var(--color-bg-button-primary-hover);      
    }
  }

  &.basic {
    border: 1px solid var(--color-border-button-basic);
    background-color: var(--color-bg-button-basic-default);
    color: var(--color-text-basic);
    
    &:active {
      background-color: var(--color-bg-button-basic-active);
    }

    &:hover {
      background-color: var(--color-bg-button-basic-hover);      
    }
  }
}

.checkbox-label {
  margin-left: var(--ion-margin);
  font-size: var(--font-size-button-small) !important;
}

.flex {
  display: flex;
}

small.sm-text {
  color: var(--ion-color-border);
}

.help-text {
  color: var(--color-text-input-help-default);
}

.linkS {
  color: var(--ion-color-primary);
  text-decoration: underline;
  margin-top: 8px;
  display: block;
  cursor: pointer;
}

.mp-input {
  background-color: var(--color-bg-input);
  color: var(--color-text-input);
  border-radius: 8px;
  border: 1px solid var(--color-border-input-default) !important;
  input {
    padding: 10px 8px !important;
  }
  &:hover,
  &:focus {
    border-color: var(--color-border-input-hover);
  }
}
.block {
  display: block;
}

ion-title {
  font-weight: bold;
}

.label-emphasis {
  font-style: normal;
  font-weight: var(--font-weight-label-emphasis);
  font-size: var(--font-size-label-emphasis);
  line-height: var(--line-height-label-emphasis);
  /* identical to box height, or 17px */

  display: flex;
  align-items: center;

  margin-bottom: 4px;
  margin-top: 16px;
}

.body-base-emphasis {
  font-weight: var(--font-weight-body-base-emphasis);
  line-height: var(--line-height-body-base);
  font-size: var(--font-size-body-base);
}

.button {
  text-transform: none;
}

ion-item {
  --border-color: transparent;
  --background: transparent;
  --background-focused: transparent;
  --background-activated-opacity: 0;
  --background-hover: transparent;
  --background-activated: transparent !important;
  span {
    font-size: 14px !important;
  }
}

.no-ripple {
  --ripple-color: transparent;
  --background-activated: transparent;
}

ion-item:focus,
ion-item:hover,
ion-item:active {
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  --background: transparent;
  --background-activated: red;
  --background-activated: transparent !important;

}

.no-border {
  border: none;
}

small {
  font-size: 12px;
}